@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .accordion-header {
    @apply py-3 px-6 cursor-pointer;
  }
  .accordion-header.active i {
    @apply transition ease-in-out duration-300;
    transform: rotateX(180deg);
  }
  .accordion-body {
    @apply max-h-0 overflow-hidden transition-all duration-200 ease-out;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(62, 62, 62);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(62, 62, 62);
}
