details {
  width: 100%;
  margin: 5px 0px 0px 0px;
}

summary {
  list-style: none;
  border: 2px solid #000;
  padding: 0.75em 1em;
  cursor: pointer;
  position: relative;
  padding-left: calc(1.75rem + 0.75rem + 0.75rem);
  font-weight: 600;
}
summary:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0.75rem;
  content: "↓";
  width: 1.75rem;
  height: 1.75rem;
  background-color: #000;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

details[open] summary:before {
  content: "↑";
}

details div {
  border: 2px solid black;
  border-top: none;
}
